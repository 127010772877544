
import Container from '../Components/Container'
import React from 'react'
function Product() {
  return (

    <div>

<Container/>

   


    </div>

  )
}

export default Product
