import React from 'react'
import Otp from '../Components/Signup/Otp';
import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
//import NavBar from '../Components/NavBar';

function OTP() {
  return (
    <div>
       <NavBar/>
       <Otp /> 
       <Footer />
       
    </div>
  )
}

export default OTP