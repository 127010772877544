import React from 'react'
import ProductDetails from '../Components/CompStore/ProductDetails'

function SingleProduct() {
  return (
   <>
   <ProductDetails/>
   </>
  )
}

export default SingleProduct