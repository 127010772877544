import AllRouters from "./AllRoutes/AllRouters";

function App() {
  return (
    <>
        <AllRouters />
    </>
  );
}

export default App;
